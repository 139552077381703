import React from 'react'
import { Grid, Typography, withWidth } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ContactViewLeftSection from './ContactViewLeftSection'
import ContactViewRightSection from './ContactViewRightSection'
import ContactViewFooter from './ContactViewFooter'

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        height: "100vh",
        width: "100%",
        maxHeight: 900,
    },
    container: {
        margin: theme.spacing(4),
        paddingRight: theme.spacing(8),
        paddingLeft: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1),
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1440,
            marginRight: 'auto',
            marginLeft: 'auto',
        },
    },
    gridContainer: {
    },
    title: {
        fontFamily: "'Ubuntu', sans-serif",
        fontSize: "2.8em",
    },
});

export default withStyles(styles)(withWidth()(({ classes, width, id }) => {

    return <div className={classes.container} id={id}>
        <Grid
            container
            alignItems="center"
            justify="space-around"
            className={classes.gridContainer}>
            <Grid item xs={12}>
                <Typography
                    variant="h3"
                    className={classes.title}
                    align="center"
                    gutterBottom>
                    Questions? Feel free to Contact Me!
                    </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container
                    direction="row"
                    alignItems="center"
                    justify="center">
                    <Grid item xs={12} sm={4}>
                        <ContactViewLeftSection />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <ContactViewRightSection />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <ContactViewFooter />
            </Grid>
        </Grid>
    </div>
}))