import React, { Component } from 'react'
import { Paper, Typography, Tabs, Tab, Grid, withWidth, Divider, AppBar } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ProjectCard from './ProjectCard'
import { projects } from './projectsdata'

const styles = theme => ({
    container: {
        margin: theme.spacing(4),
        paddingRight: theme.spacing(8),
        paddingLeft: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1),
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1440,
            marginRight: 'auto',
            marginLeft: 'auto',
        },
    },
    headingContainer: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: theme.spacing(2),
    },
    title: {
        fontFamily: "'Ubuntu', sans-serif",
        fontSize: "2.8em",
    },
    content: {
        paddingTop: 20,
        paddingBottom: 20,
        fontFamily: "'Jost', sans-serif",
        fontSize: "1.5em"
    },
    tabHeading: {

    },
    projectsContainer: {
        [theme.breakpoints.up('sm')]: {
            minHeight: "calc(100% - 64px)",
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: "calc(100% - 56px)",
        },
    },
});

export default withStyles(styles)(withWidth()(class extends Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState(window.location.href);
    }

    getInitialState(windowUrl) {
        console.log(windowUrl);
        let url = new URL(windowUrl);
        let pathName = url.pathname;
        console.log(pathName);
        if (pathName.startsWith('/work')) {
            // specific cases
            var tokens = pathName.split('/');
            var domainName = tokens[2].replace("_", " ");
            console.log(tokens);
            console.log(domainName);
            let availableDomains = this.extractDomainsFromProjects();
            var domainFound = availableDomains.find(domain => domain === domainName);
            var domainIndex = this.getDomainIndexFromName(domainFound) + 2;
            if (domainFound !== undefined) {
                return {
                    selectedDomainIndex: domainIndex,
                    domains: this.extractDomainsFromProjects(),
                    displayedProjects: this.getProjectsToDisplayFromDomainIndex(domainIndex, availableDomains),
                }
            }
        }
        // default case
        return {
            selectedDomainIndex: 1,
            domains: this.extractDomainsFromProjects(),
            displayedProjects: projects.filter(function (project) {
                return project.pinned;
            }),
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedSection !== this.props.selectedSection) {
            let newDomainIndex = this.getDomainIndexFromName(this.props.selectedSection) + 2;
            this.setState({
                selectedDomainIndex: newDomainIndex,
                displayedProjects: this.getProjectsToDisplayFromDomainIndex(newDomainIndex),
            });
        }
    }

    getDomainIndexFromName(name) {
        let domains = this.extractDomainsFromProjects();
        var i;
        for (i = 0; i < domains.length; i++) {
            if (domains[i] === name) {
                return i;
            }
        }
        return 0;
    }

    extractDomainsFromProjects() {
        let domains = new Set();
        projects.map(project => {
            domains.add(project.domain);
        });
        return Array.from(domains);
    }

    getProjectsToDisplayFromDomainIndex(domainIndex, domains = this.state.domains) {
        if (domainIndex === 0) {
            return projects;
        } else if (domainIndex === 1) {
            return projects.filter(function (project) {
                return project.pinned;
            });
        } else {
            return projects.filter(function (project) {
                return project.domain === domains[domainIndex - 2];
            });
        }
    }

    handleTabChange = (event, newIndex) => {
        this.setState({
            selectedDomainIndex: newIndex,
            displayedProjects: this.getProjectsToDisplayFromDomainIndex(newIndex),
        });
        window.history.replaceState(null, "", "/");
    }

    render() {
        const { classes, id, width, currentThemeDark } = this.props;
        const { selectedDomainIndex, domains, displayedProjects } = this.state;

        return <div className={classes.container} id={id}>
            <div className={classes.headingContainer}>
                <Typography className={classes.title} variant="h3" gutterBottom>
                    My Work
                    </Typography>
                <Typography variant="body1" className={classes.content} color="textSecondary" gutterBottom>
                    I always try to keep myself busy with projects - if not academic then personal <span role="img" aria-label="smiley">😃</span>.
                    Given below are some of the important ones I have worked on in various domains.
                </Typography>
            </div>
            <Paper elevation={3} variant="elevation">
                <AppBar position="static" color={currentThemeDark ? "default" : "secondary"}>
                    <Tabs
                        value={selectedDomainIndex}
                        indicatorColor={currentThemeDark ? "secondary" : "primary"}
                        textColor={currentThemeDark ? "secondary" : "primary"}
                        onChange={this.handleTabChange}
                        scrollButtons="auto"
                        variant={width === 'xs' || width === 'sm' || width === 'md' ? "scrollable" : "standard"}
                        centered={width !== 'xs' && width !== 'sm' && width !== 'md'}
                    >
                        <Tab label="All Projects" wrapped className={classes.tabHeading} />
                        <Tab label="Featured Projects" wrapped className={classes.tabHeading} />
                        {domains.map(domain =>
                            <Tab label={domain} className={classes.tabHeading} wrapped key={domain} />
                        )}
                    </Tabs>
                </AppBar>

                <Grid container>
                    {displayedProjects.map(project =>
                        <Grid item xs={12} key={project.name}>
                            <ProjectCard project={project} />
                            <Divider variant="middle" />
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </div>
    }
}));