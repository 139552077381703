import React from 'react'
import { InlineIcon } from '@iconify/react'
import java from '@iconify/icons-logos/java'
import swift from '@iconify/icons-logos/swift'
import c_sharp from '@iconify/icons-logos/c-sharp'
import cpp from '@iconify/icons-logos/c-plusplus'
import git from '@iconify/icons-logos/git-icon'
import firebase from '@iconify/icons-logos/firebase'
import reactivex from '@iconify/icons-logos/reactivex'
import react from '@iconify/icons-logos/react'
import android from '@iconify/icons-logos/android-icon'
import unity from '@iconify/icons-logos/unity'
import nodejs from '@iconify/icons-logos/nodejs-icon'
import ios from '@iconify/icons-logos/ios'
import awsIcon from '@iconify/icons-logos/aws'
import realm from '@iconify/icons-logos/realm'
import hadoopIcon from '@iconify/icons-logos/hadoop'
import apacheSpark from '@iconify/icons-cib/apache-spark'

export const languages = [
    {
        id: "Java",
        proficiency: "Advanced",
        description: "Preferred language, implemented complex projects using advanced language specific features",
        image: <InlineIcon icon={java} />
    },
    {
        id: "C/C++",
        proficiency: "Intermediate",
        description: "Studied thouroughly, implemeted fairly complex projects, using this language",
        image: <InlineIcon icon={cpp} />
    },
    {
        id: "C#",
        proficiency: "Novice",
        description: "Used ocassionally, if required by the task at hand & have a basic understanding of this langauge",
        image: <InlineIcon icon={c_sharp} />
    },
    {
        id: "Swift",
        proficiency: "Beginner",
        description: "Just getting started with it, implemented simple projects in an attempt to learn this language",
        image: <InlineIcon icon={swift} />
    }
]

export const technologies = [
    {
        name: "Git VCS",
        proficiency: "Advanced",
        image: <InlineIcon icon={git} />
    },
    {
        name: "Firebase",
        proficiency: "Advanced",
        image: <InlineIcon icon={firebase} />,
    },
    {
        name: "Android/WearOS",
        proficiency: "Advanced",
        image: <InlineIcon icon={android} />,
    },
    {
        name: "ReactiveX",
        proficiency: "Advanced",
        image: <InlineIcon icon={reactivex} />,
    },
    {
        name: "RealmDB",
        proficiency: "Advanced",
        image: <InlineIcon icon={realm} />,
    },
    {
        name: "React.JS",
        proficiency: "Intermediate",
        image: <InlineIcon icon={react} />,
    },
    {
        name: "Amazon Web Services",
        proficiency: "Intermediate",
        image: <InlineIcon icon={awsIcon} />,
    },
    {
        name: "Hadoop MapReduce",
        proficiency: "Intermediate",
        image: <InlineIcon icon={hadoopIcon} />,
    },
    {
        name: "Spark",
        proficiency: "Intermediate",
        image: <InlineIcon icon={apacheSpark} />,
    },
    {
        name: "Unity",
        proficiency: "Novice",
        image: <InlineIcon icon={unity} />,
    },
    {
        name: "Node.js",
        proficiency: "Novice",
        image: <InlineIcon icon={nodejs} />,
    },
    {
        name: "iOS",
        proficiency: "Novice",
        image: <InlineIcon icon={ios} />,
    }
]

// The id of each skill should match the text displayed on the tabs in work section
export const skills = [
    {
        name: "Desktop Development",
        id: "Desktop App Development",
        description: "Desktop Applications in Java/C++",
        details: "Cross-platform, GUI based desktop applications in Java & C++. Built using Java Swings for Java & SFML library for C++ apps.",
        proficiency: "Intermediate",
        image: require("../../../assets/skills/desktop_dev.webp"),
    },
    {
        name: "Android Development",
        id: "Android Development",
        description: "Android Development with Java",
        details: "Scalable & Testable Android apps built with an event-driven approach. Used advanced libraries like ReactiveX & Dagger2.",
        proficiency: "Advanced",
        image: require("../../../assets/skills/android_dev.png"),
        certificateLink: "https://www.credential.net/3fa2ffb5-074a-4253-aa82-12721a0f48c5?key=16b0df5ec16b8b6564c84747e220590fe729717765c2db0e0d349c715f7a1fac",
    },
    {
        name: "Game Development",
        id: "Game Development",
        description: "2D & 3D Game development",
        details: "Built 2D games using C++ using SDL2 & 3D games using Unity3D also integrated Unity libraries in Android enviroment.",
        proficiency: "Intermediate",
        image: require("../../../assets/skills/game_dev.webp"),
    },
    {
        name: "Web Development",
        id: "Web Development",
        description: "Website development including frontend & backend",
        details: "Node.js for backend development & React.js for frontend development",
        proficiency: "Intermediate",
        image: require("../../../assets/skills/web_dev.webp"),
    },
    {
        name: "iOS App Development",
        id: "iOS Development",
        description: "Basic iOS App development using the MVC architecture",
        details: "Worked on many mini-projects for iOS devices in an attempt to learn iOS & Swift",
        proficiency: "Novice",
        image: require("../../../assets/skills/ios_dev.png")
    }
]