import React, { Fragment } from 'react'
import { Grid, Avatar, Typography, Button, IconButton, withWidth } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { LinkedIn } from '@material-ui/icons'

const styles = theme => ({
    referenceContainer: {
        width: "100%",
        minHeight: 180,
        padding: theme.spacing(2),
        paddingTop: theme.spacing(3),
    },
    imageAvatar: {
        maxHeight: 90,
        maxWidth: 90,
        width: "100%",
        height: "auto",
        [theme.breakpoints.down('xs')]: {
            margin: "0 auto"
        },
        [theme.breakpoints.up('xl')]: {
            margin: "0 auto"
        },
    },
    imageContainer: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(1),
        },
    },
    name: {
        fontFamily: "'Jost', sans-serif",
        fontSize: "1.1em",
        fontWeight: 600,
    },
    title: {
        fontFamily: "'Jost', sans-serif",
        fontSize: "0.8em",
    },
    relation: {
        fontFamily: "'Jost', sans-serif",
        fontSize: "0.82em",
    },
    content: {
        fontFamily: "'Jost', sans-serif",
        [theme.breakpoints.up('md')]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "1em",
        },
    },
    button: {
        marginRight: theme.spacing(2),
        fontSize: "0.8em"
    },
    gridItem: {
    },
})

export default withStyles(styles)(withWidth()(({ classes, width, reference }) => {
    const contentArray = reference.content.split('\n');
    return <div>
        <Grid
            container
            direction="row"
            spacing={1}
            className={classes.referenceContainer}>
            <Grid item xs={3} className={classes.gridItem}>
                <Grid container
                    direction="row"
                    spacing={1}>
                    <Grid item sm={5} xs={12} className={classes.imageContainer}>
                        <Avatar
                            className={classes.imageAvatar}
                            src={reference.image} />
                    </Grid>
                    <Grid item sm={7} xs={12}>
                        <Typography
                            align={width === 'xs' ? "center" : "left"}
                            className={classes.name}
                            noWrap>
                            {reference.referencee}
                        </Typography>
                        <Typography
                            align={width === 'xs' ? "center" : "left"}
                            variant="caption"
                            className={classes.title}>
                            {reference.referenceeTitle}
                        </Typography>
                        <br />
                        <br />
                        <Typography
                            align={width === 'xs' ? "center" : "left"}
                            variant="caption"
                            className={classes.title}
                            gutterBottom>
                            {reference.relation}
                        </Typography>
                        <br />
                        {width === 'md' || width === 'lg' || width === 'xl' ? <Button
                            color="primary"
                            size="small"
                            onClick={() => { window.open(reference.linkedin, "_blank") }}
                            className={classes.button}
                            startIcon={<LinkedIn />}>
                            {reference.referencee}
                        </Button> : <IconButton color="primary" size="medium" aria-label="LinkedIn Profile">
                                <LinkedIn />
                            </IconButton>}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={9} className={classes.gridItem}>
                <Typography className={classes.content} variant="subtitle1" gutterBottom>
                    <span role="img" aria-label="quote">“</span>
                    {contentArray.map((item, key) => {
                        return <Fragment key={key}>{item}
                            {contentArray.length === key + 1 ? <span role="img" aria-label="un-quote">”</span> : <br />}
                        </Fragment>
                    })}
                </Typography>
                <Typography variant="overline" gutterBottom>
                    {reference.date}
                </Typography>
            </Grid>
        </Grid>
    </div>
}));