export const workex = [
    {
        companyName: "Google LLC",
        work: [
            {
                duration: "Aug 2022 - Present",
                position: "Software Engineer III",
                jobDescription: "As part of Google's OpenTelemetry team, I contribute to the open-source project's development, enhancing cloud application observability and ensuring seamless integration with Google Cloud offerings. Additionally, I maintain and support Google Cloud Profiler, a tool empowering developers to profile and analyze their cloud application performance."
            }
        ],
        companyLogo: require("../../../assets/experience/work/google.webp"),
        companyLink: "https://about.google",
    },
    {
        companyName: "Amazon Web Services Inc.",
        work: [
            {
                duration: "June 2020 - Dec 2020",
                position: "Software Development Engineer Intern",
                jobDescription: "Interned with the recommendations team in AWS Migration Hub. As an intern, I created a library that implements a proprietary scoring system to score the recommendations given to the customers by our service. I also integrated this library into the native AWS environment so that it runs as a monitoring service. During my internship, I heavily focussed on maintaining scalability and making sure the services/code I write do not adversely affect the performance/latency of other services."
            },
            {
                duration: "Aug 2021 - Aug 2022",
                position: "Software Development Engineer",
                jobDescription: "Returned to work as a full-time engineer with the recommendations team in AWS Migration Hub. Besides developing the recommendations API that recommends EC2 instances to customers migrating to AWS, worked closely with Migration Hub frontend team to develop their new AWS console experience & made significant contributions on a new service that calculates usage based credits for enterprise customers of AWS Migration Hub."
            }
        ],
        companyLogo: require("../../../assets/experience/work/aws.webp"),
        companyLink: "https://aws.amazon.com/",
    },
    {
        companyName: "mHealth Research Group, Northeastern University",
        work: [
            {
                duration: "Feb 2020 - May 2020",
                position: "Graduate Research Assistant",
                jobDescription: "Worked as a research assitant for mHealth Research group which falls under Northeastern's personal health informatics department. My responsibilities primarily include writing code to reliably collect sensor data from a user's personal devices such as smartphones and smartwatches. Other than this, fixing any bugs on Android code & scheduling various data collection services in an efficient way so as to not cause a negative impact on the user's device's battery life are some of the main techinical challenges I face."
            }
        ],
        companyLogo: require("../../../assets/experience/work/northeastern.webp"),
        companyLink: "https://www.mhealthgroup.org/",
    },
    {
        companyName: "Samagra | Transforming Governance",
        work: [
            {
                duration: "July 2019 - Aug 2019",
                position: "Technical Consultant",
                jobDescription: "As a technical consultant for the company, I assisted in developing new features for the upcoming Android projects as well as maintaining and scaling the current projects. My major focus was on developing new software architecture which was easy to test and maintain. Also converted components common across multiple projects into small-individual modules leading to better code re-usability.",
            }
        ],
        companyLogo: require("../../../assets/experience/work/samagra.webp"),
        companyLink: "https://www.samagragovernance.in/",
    },
    {
        companyName: "Carnot Technologies Pvt. Ltd.",
        work: [
            {
                duration: "May 2017 - July 2017",
                position: "Android Developer Intern",
                jobDescription: "Worked as an Android intern for the company where I was primarily tasked with implementing various feature updates & bugfixes for the CarSense app which allowed the users to track their cars and gave insights into the car's health."
            },
            {
                duration: "Feb 2018 - July 2019",
                position: "Software Development Engineer",
                jobDescription: "Led the Android Development at Carnot Technologies Pvt. Ltd. My responsibilities included app design & architecture, feature development & optimizations along with the project maintenance & testing for all the apps released by the organization. This included apps released publicly as well as the private apps required within the organization.\nDuring my time at Carnot, I also worked closely with other teams in the company - mainly firmware & backend team. Worked closely with backend team for designing various APIs & algorithms required for efficient network access in low-network areas.",
            },
        ],
        companyLogo: require("../../../assets/experience/work/carnot.webp"),
        companyLink: "http://www.carnot.co.in/",
    },
]

export const education = [
    {
        instituteName: "Northeastern University - Khoury College of Computer Science",
        institutionImage: require("../../../assets/experience/academic/northeastern.png"),
        instituteLocation: "Boston, United States",
        institutionLink: "https://www.khoury.northeastern.edu/",
        educationLevel: "Graduate Studies",
        degreeName: "Master of Science",
        fieldOfStudy: "Computer Science",
        duration: "Sept 2019 - July 2021",
        grade: "3.9 GPA",
        coursework: [
            "Algorithms",
            "Program Design Paradigm",
            "Information Retrieval",
            "Software Engineering",
            "Scalable Distributed Systems",
            "Large Scale Parallel Data Processing",
            "Game Engines",
            "Masters Project"
        ]
    },
    {
        instituteName: "Jaypee Institute of Information Technology",
        institutionLink: "http://www.jiit.ac.in/",
        institutionImage: require("../../../assets/experience/academic/jiit.png"),
        instituteLocation: "Noida, India",
        educationLevel: "Undergraduate Studies",
        degreeName: "Bachelor of Technology",
        fieldOfStudy: "Computer Science & Engineering",
        duration: "Aug 2014 - June 2018",
        grade: "7.7 CGPA",
        coursework: [
            "Algorithms",
            "Data Structures",
            "Object Oriented Programming",
            "Advanced Database",
            "Data Mining",
            "Computer Organisation & Architecture",
            "Artificial Intelligence"
        ]
    },
    {
        instituteName: "City Montessori School",
        institutionLink: "http://www.cmseducation.org/",
        institutionImage: require("../../../assets/experience/academic/cms.png"),
        instituteLocation: "Lucknow, India",
        educationLevel: "High School Studies",
        degreeName: "High School",
        fieldOfStudy: "Science, Mathematics & Computer Science",
        duration: "2012 - 2014",
        grade: "95.00%",
        coursework: [
            "Physics",
            "Chemistry",
            "Mathematics",
            "Computer Science",
            "Physical Education",
        ]
    },
]