import React, { Component, Fragment } from 'react'
import { CardContent, CardActions, Typography, Button, Chip, Avatar, Collapse, Grow, Modal, Paper, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ExpandMore, GitHub, YouTube, DateRange, AccountBalance, Code, Language, Book, GetApp } from '@material-ui/icons'
import clsx from 'clsx'
import VizSensor from 'react-visibility-sensor'

const styles = theme => ({
    containerCard: {
        width: "100%",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    projectTitle: {
        fontFamily: "'Jost', sans-serif",
    },
    projectTitleModal: {
        fontFamily: "'Jost', sans-serif",
        margin: "0 auto",
    },
    chipsContainer: {
        display: "block",
        [theme.breakpoints.up('md')]: {
            paddingRight: 20,
        },
        [theme.breakpoints.down('sm')]: {
            paddingRight: 15,
        },
        marginBottom: theme.spacing(2),
    },
    skillsChip: {
        fontFamily: "'Ubuntu', sans-serif",
        fontSize: "0.8em",
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    fontUbuntu: {
        fontFamily: "'Ubuntu', sans-serif",
    },
    button: {
        marginRight: theme.spacing(2),
    },
    marginLastElement: {
        marginBottom: theme.spacing(2),
    },
    iconSvg: {
        verticalAlign: "sub",
    },
    anchor: {
        display: "block",
        height: 90,
        marginTop: -90,
        visibility: "hidden",
    },
    showcaseContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        overflowY: 'scroll',
        display: "flex",
        margin: "auto",
        width: "80%",
        maxHeight: "80%",
        padding: theme.spacing(2, 4, 3),
    },
    showcaseVideo: {
        maxWidth: 570,
        maxHeight: 400,
        [theme.breakpoints.up('md')]: {
            height: 400,
        },
        [theme.breakpoints.down('sm')]: {
            height: 200,
        },
        width: "80%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    },
    showcaseImageCaption: {
        fontFamily: "'Jost', sans-serif",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(1),
    },
});

export default withStyles(styles)(class extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            componentVisible: false,
            openShowcase: false,
        }
    }

    getCodeAccessButtonText(githubStatus) {
        if (githubStatus === "" || githubStatus === "proprietary") {
            return "Proprietary";
        } else if (githubStatus === "private") {
            return "Request Access";
        } else {
            return "View Code";
        }
    }

    getHrefLinkForProjectCode(githubStatus, projectName) {
        if (githubStatus !== "" || githubStatus !== "proprietary") {
            if (githubStatus === "private") {
                return "mailto:hello@pranavsharma.dev?subject=Requesting Code Access for " + projectName;
            } else {
                return githubStatus;
            }
        }
    }

    handleShowCaseClick = (projectId) => {
        console.log("Project Showcase " + projectId);
        this.setState({
            openShowcase: true,
        })
    }

    handleShowCaseClose = () => {
        this.setState({
            openShowcase: false,
        })
    }

    handleExpandClick = () => {
        let currentState = this.state.expanded;
        this.setState({
            expanded: !currentState
        });
    }

    render() {
        const { classes, project } = this.props;
        const { expanded } = this.state;
        return <VizSensor onChange={(isVisible) => {
            if (!this.state.componentVisible) {
                this.setState({
                    componentVisible: isVisible
                });
            }
        }} partialVisibility>
            <div>
                <Grow in={this.state.componentVisible} style={{ transitionDelay: '16ms' }}>
                    <div className={classes.containerCard}>
                        <CardContent>
                            {/* Providing anchor to link to the URL */}
                            <span id={project.id} className={classes.anchor}></span>
                            <Typography className={classes.projectTitle} variant="h5" gutterBottom>
                                {project.name}
                            </Typography>
                            <Typography variant="overline" color="textSecondary">
                                <DateRange fontSize="small" className={classes.iconSvg} /> {project.duration} </Typography>
                            <br />
                            <Typography variant="overline" color="textSecondary">
                                <AccountBalance fontSize="small" className={classes.iconSvg} /> {project.associatedWith}
                            </Typography>
                            <br />
                            <Typography variant="overline" color="textSecondary" gutterBottom>
                                <Code fontSize="small" className={classes.iconSvg} /> {project.domain} - {project.primaryLanguage}
                            </Typography>
                            <Typography className={classes.projectTitle} variant="h6">
                                {project.description}
                            </Typography>
                            <div className={classes.chipsContainer}>
                                {project.skills.map(skill =>
                                    <Chip
                                        className={classes.skillsChip}
                                        key={skill}
                                        color="primary"
                                        size="small"
                                        avatar={<Avatar>{skill.charAt(0)}</Avatar>}
                                        label={skill}
                                    />
                                )}
                            </div>
                            <Button
                                color="primary"
                                size="small"
                                href={this.getHrefLinkForProjectCode(project.github, project.name)}
                                target="_blank"
                                disabled={project.github === "" || project.github === "proprietary"}
                                className={classes.button}
                                startIcon={<GitHub />}
                            >
                                {this.getCodeAccessButtonText(project.github)}
                            </Button>
                            {project.projectDemo === "" ? null :
                                <Button
                                    color="primary"
                                    size="small"
                                    href={project.projectDemo}
                                    target="_blank"
                                    className={classes.button}
                                    startIcon={<YouTube />}
                                >
                                    Project Demo
                </Button>
                            }
                            {
                                !project.showcase ? null :
                                    <Button
                                        color="primary"
                                        size="small"
                                        onClick={() => this.handleShowCaseClick(project.id)}
                                        target="_blank"
                                        className={classes.button}
                                        startIcon={<Book />}>
                                        Showcase
                                </Button>
                            }
                            {
                                this.state.openShowcase ? <Modal open={this.state.openShowcase}
                                    onClose={this.handleShowCaseClose}>
                                    <Paper elevation={3} className={classes.showcaseContainer}>
                                        <Grid container spacing={2} direction="row" alignItems="stretch">
                                            <Grid item xs={12} style={{ height: "auto" }}>
                                                <Typography className={classes.projectTitleModal} variant="h4" gutterBottom>
                                                    {project.name} - Showcase <span role="img" aria-label="show">🎬</span>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} alignItems="center">
                                                <Typography className={classes.projectTitleModal} variant="h5">
                                                    Screenshots & Demo <span role="img" aria-label="demmo">💻</span>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} alignItems="center">
                                                <div>
                                                    <iframe title={project.id} src={project.video} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                                                        className={classes.showcaseVideo}></iframe>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} alignItems="center">
                                                <Typography className={classes.projectTitleModal} variant="h5" align="center">
                                                    In-Game Screenshots <span role="img" aria-label="camera">📷</span>
                                                </Typography>
                                            </Grid>
                                            {
                                                project.images.map((image) => {
                                                    return <Grid item xs={12} alignItems="center">
                                                        <div>
                                                            <img src={image.source} alt={project.name} style={{ maxHeight: 400, maxWidth: 570, width: "80%", marginLeft: "auto", marginRight: "auto", display: "block" }} />
                                                        </div>
                                                        <Typography className={classes.showcaseImageCaption} variant="h6" align="center" gutterBottom>
                                                            {image.description}
                                                        </Typography>
                                                    </Grid>
                                                })
                                            }
                                            <Grid item xs={12}>
                                                <Typography className={classes.projectTitleModal} variant="h5">
                                                    Post Mortem <span role="img" aria-label="chart-down">📉</span> -
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className={classes.projectTitleModal} variant="h6">
                                                    {project.postMortem.split('\n').map((item, key) => {
                                                        return <Fragment key={key}>{item}<br /></Fragment>
                                                    })}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {
                                                    project.packageLink === "" ? null :
                                                        <Button
                                                            color="primary"
                                                            size="small"
                                                            href={project.packageLink}
                                                            target="_blank"
                                                            className={classes.marginLastElement}
                                                            startIcon={<GetApp />}>
                                                            Download Package
                                                </Button>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Modal> : null
                            }
                            {project.projectUrl === "" ? null :
                                <Button
                                    color="primary"
                                    size="small"
                                    href={project.projectUrl}
                                    target="_blank"
                                    className={classes.button}
                                    startIcon={<Language />}
                                >
                                    View Project
                </Button>
                            }
                        </CardContent>
                        <CardActions>
                            <Button size="small"
                                color="primary"
                                onClick={this.handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                                endIcon={<ExpandMore className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })} />}>{expanded ? "Hide Details" : "Show Details"}</Button>
                        </CardActions>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent>
                                <Typography variant="body1" className={classes.projectTitle}>
                                    {project.details.split('\n').map((item, key) => {
                                        return <Fragment key={key}>{item}<br /></Fragment>
                                    })}
                                </Typography>
                                <br />
                                <div className={classes.chipsContainer}>
                                    {project.technologiesUsed.map(technologyUsed =>
                                        <Chip
                                            key={technologyUsed}
                                            className={classes.skillsChip}
                                            color="primary"
                                            size="small"
                                            avatar={<Avatar>{technologyUsed.charAt(0)}</Avatar>}
                                            label={technologyUsed}
                                        />
                                    )}
                                </div>
                            </CardContent>
                        </Collapse>
                    </div>
                </Grow>
            </div>
        </VizSensor>
    }
});