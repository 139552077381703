import React, { Component } from 'react'
import { withStyles, Slide } from '@material-ui/core'
import VizSensor from 'react-visibility-sensor'

const styles = themes => ({
    contactImage: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    }
});

export default withStyles(styles)(class extends Component {

    constructor(props) {
        super(props);
        this.state = {
            componentVisible: false,
        }
        this.componentRendered = false;
    }

    componentDidMount = () => {
        this.componentRendered = true;
    }

    render() {
        const { classes } = this.props;
        return <VizSensor onChange={(isVisible) => {
            if (!this.state.componentVisible && this.componentRendered) {
                this.setState({ componentVisible: isVisible });
            }
        }} partialVisibility><div>
                <Slide direction="right" in={this.state.componentVisible} style={{ transitionDelay: '200ms' }}>
                    <div className={classes.contactImage}>
                        <img src={require("../../../assets/contact.png")} alt="contact-me" style={{ width: "100%" }} />
                    </div>
                </Slide>
            </div>
        </VizSensor>
    }
})