import React, { Component } from 'react'
import { Typography, Link, Fade } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import VizSensor from 'react-visibility-sensor'

const styles = theme => ({
    websiteCredits: {
        fontFamily: "'Jost', sans-serif",
        marginTop: theme.spacing(2),
        fontSize: "1em",
    },
    authorName: {
        fontFamily: "'Ubuntu', sans-serif",
        fontSize: "2em",
        fontWeight: 600,
    },
    imageCredits: {
        marginBottom: theme.spacing(2),
        fontSize: "0.7em",
    },
});

export default withStyles(styles)(class extends Component {

    constructor(props) {
        super(props);
        this.state = {
            componentVisible: false,
        };
    }

    render() {
        const { classes } = this.props;
        return <VizSensor onChange={(isVisible) => {
            if (!this.state.componentVisible) {
                this.setState({
                    componentVisible: isVisible
                });
            }
        }} partialVisibility>
            <div>
                <Fade in={this.state.componentVisible} style={{ transitionDelay: '200ms' }}>
                    <div>
                        <Typography
                            variant="body1"
                            align="center"
                            className={classes.websiteCredits}>
                            Website Designed & Developed By
            </Typography>
                        <Typography
                            variant="h6"
                            align="center"
                            className={classes.authorName}>
                            PRANAV SHARMA
            </Typography>
                        <Typography variant="body2" align="center" className={classes.imageCredits}>
                            <Link href="http://www.freepik.com" color="inherit" align="center">
                                Art in Skill Card Designed by fullvector / Freepik
                </Link>
                            <br />
                            <Link href="http://www.freepik.com" color="inherit" align="center">
                                Contact Art Designed by pikisuperstar / Freepik
                </Link>
                        </Typography>
                    </div>
                </Fade>
            </div>
        </VizSensor>
    }
});