import React from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontFamily: "'Ubuntu', sans-serif",
        fontSize: "2.8em",
    },
    pos: {
        paddingTop: 20,
        paddingBottom: 20,
        fontFamily: "'Jost', sans-serif",
        fontSize: "1.4em"
    },
    card: {
        width: "100%",
    }
})

export default withStyles(styles)(({ classes }) => {
    return <div className={classes.root}>
        <Typography variant="h3" className={classes.title} gutterBottom>
            About
        </Typography>
        <Typography variant="body1" className={classes.pos} color="textSecondary" gutterBottom>
            Hi<span role="img" aria-label="hand wave">👋</span>, I'm Pranav! I am a Software Engineer who enjoys solving complex problems.
            I graduated from Northeastern University in Boston with a Masters degree, majoring in Computer Science.
            <br />
        </Typography>
        <Typography variant="body1" className={classes.pos} color="textSecondary">
            I have nearly three years of professional experience as a software engineer, where I designed & developed high quality mobile applications for Android. As an Android developer, I paid great attention to detail and design of my work. Writing clean code that is easy to maintain and designing app architecture that scales well as additional, more complex features are added to a project were some of my major endeavors. <br /> In my recent years of software engineering, I spent my time exploring other interesting computer science fields - primarily distributed systems where I focussed on creating backend services that could operate on a large scale and handle immense load and user-traffic.
        </Typography>
        <Typography variant="body1" className={classes.pos} color="textSecondary">
            I have always been an enthusiastic learner looking for ways to hone my existing skills and acquire new ones. Being a firm believer in experiential learning, I have always attempted learning through application. As a result, during my time-off, I would often find myself working on some personal projects which allowed me to gain experience in a variety of domains like - Web development, iOS development and even game designing in Unity3D. Having experience in various domains often came in handy in my professional life which kept me motivated to continue learning.
        </Typography>
    </div>
})