import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Card, CardContent, Typography, Zoom } from '@material-ui/core'
import VizSensor from 'react-visibility-sensor'

const styles = theme => ({
    container: {
        maxHeight: 300,
        width: "100%",
        height: "100%",
    },
    card: {
        maxHeight: 300,
        width: "100%",
        height: "100%",
        display: "flex",
    },
    media: {
        maxHeight: 200,
        width: 150,
        marginLeft: theme.spacing(1),
        margin: "auto 0",
    },
    mediaImage: {
        width: 150,
        paddingTop: 20,
    },
    content: {
        fontFamily: "'Jost', sans-serif",
        textSize: "0.95em"
    },
    languageTitle: {
        fontFamily: "'Ubuntu', sans-serif",
    },
})

export default withStyles(styles)(class extends Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        this.componentRendered = false;
    }

    getInitialState() {
        return {
            componentVisible: false
        };
    }

    componentDidMount = () => {
        this.componentRendered = true;
    }

    render() {
        const { classes, logo, id, proficiency, description, index } = this.props;
        return <VizSensor onChange={(isVisible) => {
            if (!this.state.componentVisible && this.componentRendered) {
                this.setState({
                    componentVisible: isVisible
                });
            }
        }} partialVisibility>
            <div className={classes.container}>
                <Zoom in={this.state.componentVisible} style={{ transitionDelay: this.state.componentVisible ? 16 + (index * 80) + 'ms' : '0ms' }}>
                    <Card className={classes.card} elevation={3}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2" className={classes.languageTitle}>
                                {logo}&nbsp;&nbsp;{id}
                            </Typography>
                            <Typography variant="body2" color="textPrimary" component="p" className={classes.content} gutterBottom>
                                Proficiency: &nbsp;
                            <Typography
                                    display="inline"
                                    fontWeight="fontWeightBold"
                                    variant="overline">
                                    {proficiency}
                                </Typography>
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p" className={classes.content}>
                                {description}
                            </Typography>
                        </CardContent>
                    </Card>
                </Zoom>
            </div>
        </VizSensor>
    }
})