import React from 'react';
import ReactDOM from 'react-dom';
import App from './Components/App';
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import { blue, amber, grey } from '@material-ui/core/colors'

const getLightTheme = () => createMuiTheme({
    palette: {
        primary: {
            main: blue[800],
            dark: blue[900],
            light: blue[600],
            contrastText: "#fff",
        },
        secondary: {
            main: grey[300],
            light: amber[200],
            dark: amber[800]
        },
        type: "light"
    },
    typography: {
        "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    }
});

const getDarkTheme = () => createMuiTheme({
    palette: {
        primary: {
            main: blue[300],
            dark: blue[400],
            light: blue[200],
            contrastText: "#fff",
        },
        secondary: {
            main: amber.A400,
            light: amber[200],
            dark: amber[800]
        },
        type: "dark"
    },
    typography: {
        "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    }
})

class Index extends React.Component {
    state = {
        darkMode: localStorage.getItem("darkMode") === "true" ? true : false,
    };

    onThemeChange = () => {
        let currentState = this.state.darkMode;
        this.setState({
            darkMode: !currentState
        }, () => {
            localStorage.setItem("darkMode", !currentState);
        });
    }

    render() {
        const { darkMode } = this.state;
        return <MuiThemeProvider theme={darkMode ? getDarkTheme() : getLightTheme()}>
            <App themeChangeFunction={this.onThemeChange} currentThemeDark={darkMode} />
        </MuiThemeProvider>
    }
}

ReactDOM.render(
    <React.StrictMode>
        <Index />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
