import React, { Fragment, Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, withWidth } from '@material-ui/core'
import SkillCardLanguage from './SkillCardLanguage'
import { languages, skills } from './skillstore'
import SkillCard from './SkillCard'
import SkillsFramework from './SkillsFramework'

const styles = theme => ({
    container: {
        margin: theme.spacing(4),
        paddingRight: theme.spacing(8),
        paddingLeft: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1),
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1440,
            marginRight: 'auto',
            marginLeft: 'auto',
        },
    },
    title: {
        fontFamily: "'Ubuntu', sans-serif",
        fontSize: "2.8em",
    },
    subsection: {
        fontFamily: "'Ubuntu', sans-serif",
        paddingTop: 10,
        fontSize: "2em",
    },
    headingContainer: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: theme.spacing(2),
    },
    gridContainer: {
        marginBottom: theme.spacing(2),
    },
    content: {
        paddingTop: 20,
        paddingBottom: 20,
        fontFamily: "'Jost', sans-serif",
        fontSize: "1.5em"
    },
})

export default withStyles(styles)(withWidth()(class extends Component {

    render() {
        const { classes, id, dataChangeCallback, width } = this.props;
        return <Fragment>
            <div className={classes.container} id={id}>
                <div className={classes.headingContainer}>
                    <Typography className={classes.title} variant="h3" gutterBottom>
                        My Skillset
                </Typography>
                    <Typography variant="body1" className={classes.content} color="textSecondary" gutterBottom>
                        Over the years, I have acquired skills in many domains. Given below are the areas in which I am skilled along with a list of technologies & frameworks in which I am experienced <span role="img" aria-label="smiley">😁</span>
                    </Typography>
                </div>
                <Typography gutterBottom className={classes.subsection}>
                    What I can do for you <span role="img" aria-label="direction">👉🏼</span>
                </Typography>
                <Grid
                    container
                    alignItems="stretch"
                    justify="center"
                    spacing={1}
                    className={classes.gridContainer}>
                    {skills.map((skill, index) =>
                        <Grid item key={skill.name} xs={12} sm={6} md={4} lg={3}>
                            <SkillCard
                                name={skill.name}
                                id={skill.id}
                                index={index}
                                details={skill.details}
                                description={skill.description}
                                proficiency={skill.proficiency}
                                image={skill.image}
                                certification={skill.certificateLink}
                                dataChangeCallback={dataChangeCallback} />
                        </Grid>
                    )}
                </Grid>
                <Typography gutterBottom className={classes.subsection}>
                    Programming Languages I know <span role="img" aria-label="direction">👉🏼</span>
                </Typography>
                <Grid
                    container
                    justify="center"
                    alignItems="stretch"
                    spacing={1}
                    className={classes.gridContainer}>
                    {languages.map((language, index) =>
                        <Grid key={language.id} item xs={12} sm={6} md={4} lg={3}>
                            <SkillCardLanguage
                                logo={language.image}
                                id={language.id}
                                index={index}
                                description={language.description}
                                proficiency={language.proficiency} />
                        </Grid>
                    )}
                </Grid>
                <Typography gutterBottom className={classes.subsection}>
                    Technologies & Frameworks I have worked on <span role="img" aria-label="direction">👉🏼</span>
                </Typography>
                <SkillsFramework width={width} />
            </div>
        </Fragment>
    }
}));