import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Divider, Grid, Typography } from '@material-ui/core'
import { DoneAllOutlined } from '@material-ui/icons'

const styles = theme => ({
    root: {
        width: "100%",
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1440,
            marginRight: 'auto',
            marginLeft: 'auto',
        },
    },
    leftDivider: {
        marginLeft: theme.spacing(4),
        borderWidth: 5,
    },
    rightDivider: {
        marginRight: theme.spacing(4),
        borderWidth: 5,
    },
})

export default withStyles(styles)(({ classes, id }) => {
    return <div className={classes.root} id={id}>
        <Grid container
            direction="row"
            alignItems="center">
            <Grid item xs={5}>
                <Divider className={classes.leftDivider} variant="fullWidth" />
                <Divider className={classes.leftDivider} variant="fullWidth" />
            </Grid>
            <Grid item xs={2}>
                <Typography align="center">
                    <DoneAllOutlined fontSize="large" color="inherit" />
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Divider className={classes.rightDivider} variant="fullWidth" />
                <Divider className={classes.rightDivider} variant="fullWidth" />
            </Grid>
        </Grid>
    </div>
})
