import React, { Fragment } from 'react'
import { Grid } from '@material-ui/core'
import IntroductionCard from './IntroductionCard'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    container: {
        margin: theme.spacing(4),
        paddingRight: theme.spacing(8),
        paddingLeft: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1),
            marginTop: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1440,
            marginRight: 'auto',
            marginLeft: 'auto',
        },
    }
})

export default withStyles(styles)(({ classes, id }) => {
    return <Fragment>
        <div className={classes.container}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                id={id}>
                <Grid item xs={12}>
                    <IntroductionCard />
                </Grid>
            </Grid>
        </div>
    </Fragment>
});