import React, { Component } from 'react'
import { Grid, Typography, Paper, Chip, Avatar, IconButton, Divider, Slide } from '@material-ui/core'
import { Http } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { School, Grade, Assignment } from '@material-ui/icons'
import VizSensor from 'react-visibility-sensor'

const styles = theme => ({
    gridPaper: {
        height: "100%",
    },
    leftPaperText: {
        fontFamily: "'Jost', sans-serif",
        fontSize: "1.5em",
    },
    instituteName: {
        fontFamily: "'Jost', sans-serif",
        [theme.breakpoints.up('md')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 15,
        },
    },
    instituteLocation: {
        fontFamily: "'Jost', sans-serif",
        [theme.breakpoints.up('md')]: {
            paddingLeft: 20,
            paddingRight: 20,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 15,
            paddingRight: 15,
        },
    },
    chipsContainer: {
        display: "block",
        [theme.breakpoints.up('md')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 25,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 15,
            paddingRight: 15,
            paddingBottom: 20,
        },
    },
    courseChip: {
        fontFamily: "'Ubuntu', sans-serif",
        fontSize: "0.88em",
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    instituteImage: {
        width: "100%",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    leftPaperGridItem: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    iconSvg: {
        verticalAlign: "sub",
    },
    httpLinkButton: {
        paddingTop: 0,
        paddingBottom: 0,
    },
})

export default withStyles(styles)(class extends Component {

    constructor(props) {
        super(props);
        this.state = {
            componentVisible: false
        };
        this.componentRendered = false;
    }

    componentDidMount = () => {
        this.componentRendered = true;
    }

    render() {
        const { classes, education, index } = this.props;
        const { instituteName, institutionImage, instituteLocation, institutionLink, educationLevel, degreeName, fieldOfStudy, duration, grade, coursework } = education;
        return <VizSensor onChange={(isVisible) => {
            if (!this.state.componentVisible && this.componentRendered) {
                this.setState({
                    componentVisible: isVisible
                });
            }
        }} partialVisibility>
            <div>
                <Slide direction={index % 2 === 0 ? "left" : "right"} in={this.state.componentVisible} style={{ transitionDelay: this.state.componentVisible ? 16 + (index * 50) + 'ms' : '0ms' }}>
                    <Paper elevation={3}><Grid
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="stretch">
                        <Grid item
                            xs={12} sm={4} md={3}>
                            <div className={classes.gridPaper}>
                                <Grid container
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                    className={classes.gridPaper}>
                                    <Grid item className={classes.leftPaperGridItem}>
                                        <img src={institutionImage} className={classes.instituteImage} alt={instituteName} />
                                    </Grid>
                                    <Grid item className={classes.leftPaperGridItem}>
                                        <Typography variant="subtitle1" className={classes.leftPaperText} align="center">
                                            {educationLevel}
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.leftPaperGridItem}>
                                        <Typography variant="subtitle1" className={classes.leftPaperText} align="center" gutterBottom>
                                            {duration}
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </div>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={12} sm={7} md={8}>
                            <div className={classes.gridPaper}>
                                <Typography fontWeight="fontWeightBold" variant="h5" className={classes.instituteName}>
                                    {instituteName}
                                    <IconButton href={institutionLink} target="_blank" color="primary" className={classes.httpLinkButton} aria-label="visit website">
                                        <Http />
                                    </IconButton>
                                </Typography>
                                <Typography fontWeight="fontWeightBold" variant="overline" className={classes.instituteLocation} gutterBottom>
                                    {instituteLocation}
                                </Typography>
                                <br />
                                <br />
                                <Typography fontWeight="fontWeightBold" variant="h6" className={classes.instituteLocation} gutterBottom>
                                    <School className={classes.iconSvg} /> {degreeName}, {fieldOfStudy}
                                </Typography>
                                <Typography fontWeight="fontWeightBold" variant="h6" className={classes.instituteLocation} gutterBottom>
                                    <Grade className={classes.iconSvg} /> {grade}
                                </Typography>
                                <Typography fontWeight="fontWeightBold" variant="h6" className={classes.instituteLocation}>
                                    <Assignment className={classes.iconSvg} /> Related Coursework -
                                </Typography>
                                <div className={classes.chipsContainer}>
                                    {coursework.map(course =>
                                        <Chip
                                            className={classes.courseChip}
                                            key={course}
                                            color="primary"
                                            size="small"
                                            avatar={<Avatar>{course.charAt(0)}</Avatar>}
                                            label={course} />
                                    )}
                                </div>
                            </div>
                        </Grid>
                    </Grid >
                    </Paper>
                </Slide>
            </div>
        </VizSensor>
    }
});            