import React, { Component } from 'react'
import { Grid, Typography, Button, Fab, withWidth, Tooltip, Slide, Zoom } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Email, GitHub, LinkedIn, Facebook, Twitter } from '@material-ui/icons'
import firebase from '../../../Firebase'
import VizSensor from 'react-visibility-sensor'

const styles = theme => ({
    contentText: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            fontSize: 22,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            fontSize: 18,
        },
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    buttonContainer: {
        textAlign: "center",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    socialConnectButtons: {
        marginBottom: theme.spacing(2),
    },
});

export default withStyles(styles)(withWidth()(class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentVisible: false,
        };
        this.componentRendered = false;
    }

    handleSocialMediaClick(socialMedia) {
        var analytics = firebase.analytics();
        analytics.logEvent('view_social_media', {
            content_type: socialMedia
        });
    }

    componentDidMount = () => {
        this.componentRendered = true;
    }

    render() {
        const { classes, width } = this.props;
        return <VizSensor onChange={(isVisible) => {
            if (!this.state.componentVisible && this.componentRendered) {
                this.setState({
                    componentVisible: isVisible
                });
            }
        }} partialVisibility>
            <div>
                <Slide direction="left" in={this.state.componentVisible} style={{ transitionDelay: '200ms' }}>
                    <div>
                        <Typography
                            variant="body1"
                            align="center"
                            className={classes.contentText}>
                            Wanna reach out to me ?<br />Just send me an email and we can talk!<span role="img" aria-label="smiley">😁</span>
                        </Typography>
                        <div className={classes.buttonContainer}>
                            <Tooltip
                                title="hello@pranavsharma.dev"
                                TransitionComponent={Zoom}
                                placement={width === 'lg' || width === 'xl' ? "right" : "bottom"}
                                enterDelay={300}
                                leaveDelay={500}
                                arrow>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    href="mailto:hello@pranavsharma.dev?subject=Hi Pranav!"
                                    onClick={this.handleSocialMediaClick('Mail')}
                                    startIcon={<Email />}>
                                    Say Hello
                            </Button>
                            </Tooltip>
                        </div>
                        <Typography
                            variant="body1"
                            align="center"
                            className={classes.contentText}>
                            Or, you can connect with me on my social media! <span role="img" aria-label="smiley">😄</span>
                        </Typography>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            spacing={3}
                            className={classes.socialConnectButtons}>
                            <Grid item>
                                <Fab
                                    size={width === 'xs' || width === 'sm' ? "medium" : "large"}
                                    href="https://www.linkedin.com/in/sharma-pranav/"
                                    onClick={this.handleSocialMediaClick('LinkedIn')}
                                    target="_blank"
                                    color="primary">
                                    <LinkedIn />
                                </Fab>
                            </Grid>
                            <Grid item>
                                <Fab
                                    size={width === 'xs' || width === 'sm' ? "medium" : "large"}
                                    href="https://github.com/psx95"
                                    onClick={this.handleSocialMediaClick('GitHub')}
                                    target="_blank"
                                    color="primary">
                                    <GitHub />
                                </Fab>
                            </Grid>
                            <Grid item>
                                <Fab
                                    size={width === 'xs' || width === 'sm' ? "medium" : "large"}
                                    href="https://www.facebook.com/pranav.sharma.1804"
                                    onClick={this.handleSocialMediaClick('Facebook')}
                                    target="_blank"
                                    color="primary">
                                    <Facebook />
                                </Fab>
                            </Grid>
                            <Grid item>
                                <Fab
                                    size={width === 'xs' || width === 'sm' ? "medium" : "large"}
                                    href="https://twitter.com/1PranavSharma"
                                    onClick={this.handleSocialMediaClick('Twitter')}
                                    target="_blank"
                                    color="primary">
                                    <Twitter />
                                </Fab>
                            </Grid>
                        </Grid>
                    </div>
                </Slide>
            </div>
        </VizSensor>
    }
}));