import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/analytics'

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDhunwwl9eowBIKVaJl8pUDmfVKDLDlwUE",
    authDomain: "portfolio-site-ffa35.firebaseapp.com",
    databaseURL: "https://portfolio-site-ffa35.firebaseio.com",
    projectId: "portfolio-site-ffa35",
    storageBucket: "portfolio-site-ffa35.appspot.com",
    messagingSenderId: "1003697896798",
    appId: "1:1003697896798:web:4e8a888ffb63aafabae3cf",
    measurementId: "G-2PCNCRCX79"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;