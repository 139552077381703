import React, { Component } from 'react'
import { Paper, Typography, Button, Slide } from '@material-ui/core'
import { Grade } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-scroll'
import VizSensor from 'react-visibility-sensor'

const styles = theme => ({
    root: {
        width: "100%",
        height: "100%",
        maxHeight: 420,
        position: "relative",
    },
    media: {
        height: "35.7%",
        maxHeight: 150,
        width: "100%",
        objectFit: "cover",
    },
    title: {
        fontFamily: "'Ubuntu', sans-serif",
    },
    content: {
        fontFamily: "'Jost', sans-serif",
    },
    cardContent: {
        maxHeight: "50%",
        overflowY: "auto",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    cardActions: {
        position: "absolute",
        bottom: 0,
        maxHeight: "14.25%",
        padding: theme.spacing(1),
    },
    buttonHalf: {
        width: "49%",
        overflowX: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    buttonFull: {
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    }
})

export default withStyles(styles)(class extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
        this.componentRendered = false;
    }

    componentDidMount = () => {
        this.componentRendered = true;
    }

    render() {
        const { classes, name, id, details, description, image, certification, dataChangeCallback, index } = this.props;
        return <VizSensor
            onChange={(isVisible) => {
                if (!this.state.visible && this.componentRendered) {
                    this.setState({ visible: isVisible });
                }
            }} partialVisibility>
            <div className={classes.root}>
                <Slide direction="left" in={this.state.visible} style={{ transitionDelay: this.state.visible ? 16 + (index * 50) + 'ms' : '0ms' }}>
                    <Paper elevation={3} style={{ height: "100%" }}>
                        <img className={classes.media} alt={name} src={image} />
                        <div className={classes.cardContent}>
                            <Typography gutterBottom variant="h6" className={classes.title}>
                                {name}
                            </Typography>
                            <Typography variant="body1" color="textPrimary" className={classes.content}>
                                {description}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p" className={classes.content}>
                                {details}
                            </Typography>
                        </div>
                        <div className={classes.cardActions}>
                            <Link
                                to="work"
                                smooth={true}
                                spy={true}
                                duration={800}
                            >
                                <Button size="small" color="primary" onClick={() => dataChangeCallback(id)} className={certification == null ? classes.buttonFull : classes.buttonHalf}>
                                    View Projects
                            </Button>
                            </Link>
                        &nbsp;
                        {certification == null ? null : <Button href={certification} target="_blank" size="small" color="primary" endIcon={<Grade />} className={classes.buttonHalf}>
                                Certification
                        </Button>}
                        </div>
                    </Paper>
                </Slide>
            </div>
        </VizSensor>
    }
});