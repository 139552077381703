import React, { Component } from 'react'
import { AppBar, Toolbar, Typography, Tabs, Tab, withWidth, IconButton, Tooltip, Zoom } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { NightsStay, WbSunny } from '@material-ui/icons'
import WelcomeView from './WelcomeView'
import { Link } from "react-scroll"
import { grey } from '@material-ui/core/colors'

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    toolbar: {
        flexGrow: 1,
        justifyContent: "space-between",
        [theme.breakpoints.down('xs')]: {
            justifyContent: "center"
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    appBarTitle: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        fontFamily: "'Pacifico', cursive",
    },
    appBarTabs: {
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        background: "primary",
    },
    appBarDark: {
        background: grey[800],
    },
    appBarTransparent: {
        background: "transparent",
        boxShadow: "none"
    },
    greyColor: {
        color: grey[300],
    }
});

export default withStyles(styles)(withWidth()(class extends Component {
    sectionToNameMapping = new Map([
        ["about", 0],
        ["skills", 1],
        ["experience", 2],
        ["work", 3],
        ["references", 4],
        ["contact", 5],
    ]);

    state = {
        makeAppBarTransparent: true,
        selectedIndex: false,
        scrollingUp: false,
    }

    constructor(props) {
        super(props);
        this.startingY = window.screenY;
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        //   window.addEventListener('wheel', this.handleScrollDirection);
        //  window.addEventListener('touchstart', this.handleTouchStart);
        // window.addEventListener('touchmove', this.handleTouchMove);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        // window.removeEventListener('wheel', this.handleScrollDirection);
        //window.removeEventListener('touchstart', this.handleTouchStart);
        // window.removeEventListener('touchmove', this.handleTouchMove);
    }

    handleTouchStart = (event) => {
        this.startingY = event.changedTouches[0].pageY;
    }

    handleTouchMove = (event) => {
        var currentY = event.changedTouches[0].pageY;
        var delta = currentY - this.startingY;
        if (delta > 0) {
            // UP
            let currentDirection = this.state.scrollingUp;
            if (!currentDirection) {
                this.setState({
                    scrollingUp: true,
                });
            }
        } else {
            // DOWN
            let currentDirection = this.state.scrollingUp;
            if (currentDirection) {
                this.setState({
                    scrollingUp: false,
                });
            }
        }
    }

    handleScroll = event => {
        let transparent = true;
        if (window.scrollY > 100) {
            transparent = false;
        } else {
            transparent = true;
            this.setState({
                selectedIndex: false
            });
        }
        let currentStateTransparent = this.state.makeAppBarTransparent;
        if (currentStateTransparent !== transparent) {
            this.setState({
                makeAppBarTransparent: transparent
            });
        }
    }

    handleScrollDirection = (event) => {
        if (this.checkScrollDirectionIsUp(event)) {
            // Up
            let currentDirection = this.state.scrollingUp;
            if (!currentDirection) {
                this.setState({
                    scrollingUp: true,
                });
            }
        } else {
            let currentDirection = this.state.scrollingUp;
            if (currentDirection) {
                this.setState({
                    scrollingUp: false,
                });
            }
        }
    }

    checkScrollDirectionIsUp = (event) => {
        if (event.wheelDelta) {
            return event.wheelDelta > 0;
        }
        return event.deltaY < 0;
    }

    handleLinkActive = (section) => {
        this.setState({
            selectedIndex: this.sectionToNameMapping.get(section)
        })
    }

    handleLinkInactive = () => {
        let scrollUp = this.state.scrollingUp;
        if (scrollUp) {
            let currentIndex = this.state.selectedIndex;
            if (currentIndex > 0) {
                this.setState({
                    selectedIndex: currentIndex - 1,
                });
            }
        }
    }

    render() {
        const { classes, width, themeChangeFunction, currentThemeDark } = this.props;
        const { makeAppBarTransparent, selectedIndex } = this.state;

        return <div className={classes.root}>
            <AppBar
                position="fixed"
                className={makeAppBarTransparent ? classes.appBarTransparent : currentThemeDark ? classes.appBarDark : classes.appBar}
                color={makeAppBarTransparent ? "transparent" : "primary"}>
                <Toolbar className={classes.toolbar}>
                    {makeAppBarTransparent ? null : <Typography className={classes.appBarTitle} variant={width === 'sm' || width === 'md' ? "body1" : "h5"}>
                        Pranav Sharma
                    </Typography>}
                    <Typography></Typography>
                    <Tabs
                        value={makeAppBarTransparent ? false : selectedIndex}
                        aria-label="page section quick links"
                        variant={width === 'xs' || width === 'sm' ? "scrollable" : "standard"}
                        centered={width !== 'xs' && width !== 'sm'}>
                        <Link
                            to="about"
                            smooth={true}
                            spy={true}
                            offset={-70}
                            duration={500}
                            onSetActive={this.handleLinkActive}>
                            <Tab label={makeAppBarTransparent ? <span className={classes.greyColor}>About</span> : "About"} />
                        </Link>
                        <Link
                            to="skills"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onSetActive={this.handleLinkActive}>
                            <Tab label={makeAppBarTransparent ? <span className={classes.greyColor}>Skills</span> : "Skills"} />
                        </Link>
                        <Link
                            to="experience"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-70}
                            onSetActive={this.handleLinkActive}>
                            <Tab label={makeAppBarTransparent ? <span className={classes.greyColor}>Experience</span> : "Experience"} />
                        </Link>
                        <Link
                            to="work"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onSetActive={this.handleLinkActive}>
                            <Tab label={makeAppBarTransparent ? <span className={classes.greyColor}>Work</span> : "Work"} />
                        </Link>
                        <Link
                            to="references"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onSetActive={this.handleLinkActive}>
                            <Tab label={makeAppBarTransparent ? <span className={classes.greyColor}>Reference</span> : "Reference"} />
                        </Link>
                        <Link
                            to="contact"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onSetActive={this.handleLinkActive}>
                            <Tab label={makeAppBarTransparent ? <span className={classes.greyColor}>Contact</span> : "Contact"} />
                        </Link>
                    </Tabs>
                    <Tooltip TransitionComponent={Zoom} disableFocusListener title={currentThemeDark ? "Too Dark? Click to Switch Back !" : "Too Bright? Try Dark Mode !"} arrow>
                        <IconButton onClick={themeChangeFunction}>
                            {currentThemeDark ? <WbSunny fontSize="default" className={classes.greyColor} /> :
                                <NightsStay fontSize="default" className={classes.greyColor} />}
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <WelcomeView />
        </div>
    }
}));