import React, { Component } from 'react'
import { Grid, Paper, Typography, Stepper, Step, StepLabel, StepButton, IconButton, StepConnector, Divider, Slide } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Http, Check, Timeline } from '@material-ui/icons'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import VizSensor from 'react-visibility-sensor'
import { Fragment } from 'react'

const styles = theme => ({
    leftPaper: {
        height: "100%",
    },
    rightPaper: {
        height: "100%",
    },
    leftPaperGridContainer: {
        height: "100%",
    },
    content: {
        fontFamily: "'Jost', sans-serif",
        [theme.breakpoints.up('md')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
            fontSize: "1.2em",
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 10,
            fontSize: "1.1em",
        },
    },
    contentContainer: {
        overflowY: "auto",
        height: 180,
    },
    companyLogo: {
        width: "100%",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    companyTitle: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 10,
        },
        fontFamily: "'Jost', sans-serif",
    },
    stepperContainer: {
        width: "100%",
        display: "flex",
    },
    stepper: {
        alignSelf: "center",
    },
    durationBreakIcon: {
        color: theme.palette.type === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
        marginRight: "2px",
        marginLeft: "2px",
        paddingRight: "1px",
        paddingLeft: "1px",
    },
});

const QontoConnector = withStyles(theme => ({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: theme.palette.type === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
        },
    },
    completed: {
        '& $line': {
            borderColor: theme.palette.type === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}))(StepConnector);


const useQontoStepIconStyles = makeStyles(theme => ({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: theme.palette.type === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: theme.palette.type === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
        zIndex: 1,
        fontSize: 18,
    },
}));

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};

export default withStyles(styles)(class extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentWorkIndex: this.props.work.length - 1,
            componentVisible: false,
        }
        this.componentRendered = false;
    }

    componentDidMount = () => {
        this.componentRendered = true;
    }

    /**
     * This method returns the textual representation of total time spent in a company.
     * The time is calculated through the data available in the work array, passed through props.
     * @param work - The array that contains the work durations at a given company
     */
    getDurationTextFromWork(work) {
        if (work.length === 1) {
            return [work[0].duration];
        } else {
            var workDurationArray = [];
            var count = 0;
            work.map((workObject) => {
                workDurationArray[count++] = workObject.duration;
            })
            return workDurationArray;
        }
    }

    getStartFromWorkDuration(workDuration) {
        return workDuration.substring(0, workDuration.indexOf("-"));
    }

    handleStepChange = (index) => {
        console.log("on step " + index);
        this.setState({
            currentWorkIndex: index
        });
    }

    handleStepLabel = (work, index) => {
        if (index === work.length - 1) {
            return work[index].duration.substring(work[index].duration.indexOf("-") + 1);
        } else {
            return <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <span style={{ verticalAlign: "middle" }}>{work[index].duration.substring(work[index].duration.indexOf("-") + 1)}</span>
                <Timeline className={this.props.classes.durationBreakIcon} />
                <span style={{ verticalAlign: "middle" }}>{work[index + 1].duration.substring(0, work[index + 1].duration.indexOf("-"))}</span>
            </div>;
        }
    }

    render() {
        // index is the position of the workexperience obect in the array
        const { classes, companyName, companyLogo, work, companyLink, index } = this.props;
        const { currentWorkIndex } = this.state;

        return <VizSensor onChange={(isVisible) => {
            if (!this.state.componentVisible && this.componentRendered) {
                this.setState({
                    componentVisible: isVisible
                })
            }
        }} partialVisibility>
            <div>
                <Slide direction={(index % 2 === 0) ? "left" : "right"}
                    in={this.state.componentVisible} style={{ transitionDelay: this.state.componentVisible ? 16 + (index * 50) + 'ms' : '0ms' }}>
                    <Paper elevation={3}><Grid
                        container
                        spacing={0}
                        justify="space-evenly"
                        alignItems="stretch">
                        <Grid item
                            xs={12} sm={4} md={3}>
                            <div className={classes.leftPaper}>
                                <Grid
                                    container
                                    alignItems="center"
                                    direction="column"
                                    className={classes.leftPaperGridContainer}
                                    justify="center">
                                    <Grid item className={classes.im}>
                                        <img src={companyLogo} alt={companyName} className={classes.companyLogo} />
                                    </Grid>
                                    <Grid item>
                                        <Fragment>
                                            {this.getDurationTextFromWork(work).map((workDuration) => {
                                                return (<Typography className={classes.content} align="center" gutterBottom>
                                                    {workDuration}
                                                </Typography>)
                                            })}
                                        </Fragment>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Divider orientation="vertical" flexItem />

                        <Grid item xs={12} sm={7} md={8}>
                            <div className={classes.rightPaper}>
                                <Typography variant="h5" className={classes.companyTitle} fontWeight="fontWeightBold">
                                    {companyName}
                                    <IconButton href={companyLink} target="_blank" color="primary" aria-label="visit website">
                                        <Http />
                                    </IconButton>
                                </Typography>
                                <Typography variant="h6" className={classes.companyTitle} fontWeight="fontWeightBold">
                                    {work[currentWorkIndex].position}
                                    <Typography variant="overline" className={classes.companyTitle}>{work[currentWorkIndex].duration}</Typography>
                                </Typography>

                                <div className={classes.contentContainer}>
                                    <Typography variant="subtitle1" className={classes.content}>
                                        {work[currentWorkIndex].jobDescription}
                                    </Typography>
                                </div>
                                <div>
                                    <Stepper nonLinear activeStep={currentWorkIndex + 1} connector={<QontoConnector />}>
                                        <Step completed={false}>
                                            <StepLabel StepIconComponent={QontoStepIcon}>{this.getStartFromWorkDuration(work[0].duration)}</StepLabel>
                                        </Step>
                                        {work.map((currentWork, i) =>
                                            <Step key={currentWork.duration} completed={true}>
                                                <StepButton onClick={() => this.handleStepChange(i)}>
                                                    <StepLabel StepIconComponent={QontoStepIcon}>{this.handleStepLabel(work, i)}</StepLabel>
                                                </StepButton>
                                            </Step>
                                        )}
                                    </Stepper>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    </Paper>
                </Slide>
            </div>
        </VizSensor>
    }
});