import React, { Component } from 'react'
import { Paper, Typography, Grid, Stepper, Step, StepButton, Divider, IconButton, Hidden } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons'
import ReferenceCard from './ReferenceCard'
import { references } from './referencestore'

const styles = theme => ({
    container: {
        margin: theme.spacing(4),
        paddingRight: theme.spacing(8),
        paddingLeft: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1),
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1440,
            marginRight: 'auto',
            marginLeft: 'auto',
        },
    },
    headingContainer: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: theme.spacing(2),
    },
    title: {
        fontFamily: "'Ubuntu', sans-serif",
        fontSize: "2.8em",
    },
    content: {
        paddingTop: 20,
        paddingBottom: 20,
        fontFamily: "'Jost', sans-serif",
        fontSize: "1.5em"
    },
    stepperContainer: {
        width: "100%"
    },
    stepperButtonContainer: {
        display: "flex",
        justifyContent: "center",
    }
})

export default withStyles(styles)(class extends Component {
    state = {
        currentReferenceIndex: 0,
    }

    handleReferenceChange = (index) => {
        this.setState({
            currentReferenceIndex: index,
        })
    }

    increaseSelectedReferenceIndex = () => {
        let prevIndex = this.state.currentReferenceIndex;
        if (prevIndex < references.length - 1) {
            this.setState({
                currentReferenceIndex: prevIndex + 1,
            });
        }
    }

    decreaseSelectedReferenceIndex = () => {
        let prevIndex = this.state.currentReferenceIndex;
        if (prevIndex > 0) {
            this.setState({
                currentReferenceIndex: prevIndex - 1,
            });
        }
    }

    render() {
        const { classes, id } = this.props;
        const { currentReferenceIndex } = this.state;

        return <div className={classes.container} id={id}>
            <div className={classes.headingContainer}>
                <Typography className={classes.title} variant="h3" gutterBottom>
                    My References
                </Typography>
                <Typography variant="body1" className={classes.content} color="textSecondary" gutterBottom>
                    During my journey so far, I have come across many smart & interesting people.
                    Here are a few of them and their thoughts about me & my work <span role="img" aria-label="smiley">😊</span>.
                </Typography>
            </div>
            <Grid container justify="center" alignItems="center">
                <Paper elevation={3}>
                    <Grid item xs={12}>
                        <ReferenceCard reference={references[currentReferenceIndex]} />
                    </Grid>
                    <Divider variant="middle" />
                    <Grid item xs={12}>
                        <Grid container
                            spacing={1}
                            direction="row"
                            justify="center"
                            alignContent="center"
                            alignItems="center">
                            <Grid item sm={2}>
                                <Hidden xsDown>
                                    <div className={classes.stepperButtonContainer}>
                                        <IconButton
                                            aria-label="previous-reference"
                                            disabled={currentReferenceIndex === 0}
                                            onClick={() => this.decreaseSelectedReferenceIndex()}>
                                            <ArrowBackIos />
                                        </IconButton>
                                    </div>
                                </Hidden>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <div className={classes.stepperContainer}>
                                    <Stepper nonLinear activeStep={currentReferenceIndex}>
                                        {references.map((reference, index) => {
                                            return (
                                                <Step key={index}>
                                                    <StepButton
                                                        onClick={() => this.handleReferenceChange(index)}
                                                    >
                                                    </StepButton>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </div>
                            </Grid>
                            <Grid item sm={2}>
                                <Hidden xsDown>
                                    <div className={classes.stepperButtonContainer}>
                                        <IconButton
                                            aria-label="next-reference"
                                            disabled={currentReferenceIndex === references.length - 1}
                                            onClick={() => this.increaseSelectedReferenceIndex()}>
                                            <ArrowForwardIos />
                                        </IconButton>
                                    </div>
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </div>
    }
})