import React, { Component, Fragment } from 'react';
import { CssBaseline } from '@material-ui/core';
import Header from './Layouts/Header';
import { withStyles } from '@material-ui/core/styles';
import AboutSection from './Sections/About/AboutSection';
import SkillSection from './Sections/Skills/SkillSection';
import ExperienceSection from './Sections/Experience/ExperienceSection';
import WorkSection from './Sections/Work/WorkSection';
import ReferenceSection from './Sections/References/ReferenceSection';
import Contact from './Layouts/contact/ContactView';
import SectionDivider from './Layouts/SectionDivider';

const styles = {
    '@global': {
        'html ,body, #root': {
            height: '100%',
        }
    }
}

class App extends Component {

    state = {
        selectedProjectDomain: null
    }

    onGlobalDataChange = (selectedProjectDomainName) => {
        this.setState({
            selectedProjectDomain: selectedProjectDomainName
        });
    }

    render() {
        return <Fragment>
            <CssBaseline />
            <div style={{ position: "absolute", overflowX: "hidden", width: "100%" }}>
                <Header themeChangeFunction={this.props.themeChangeFunction} currentThemeDark={this.props.currentThemeDark} />
                <AboutSection id={"about"} />
                <SectionDivider />
                <SkillSection id={"skills"} dataChangeCallback={this.onGlobalDataChange} />
                <SectionDivider />
                <ExperienceSection id={"experience"} />
                <SectionDivider />
                <WorkSection id={"work"} selectedSection={this.state.selectedProjectDomain} currentThemeDark={this.props.currentThemeDark} />
                <SectionDivider />
                <ReferenceSection id={"references"} />
                <SectionDivider />
                <Contact id={"contact"} />
            </div>
        </Fragment>
    }
}

export default withStyles(styles)(App);
