export const references = [
    {
        referencee: "Chakshu Gautam",
        referenceeTitle: "Senior Software Engineer at Samagra | Transforming Governance",
        relation: "Chakshu was a client of Pranav’s",
        content: "Pranav worked with Samagra on the Android Application architecture and did a wonderful job setting up the systems and was able to push code with high quality at a really high pace. His deep knowledge in Android and Java specifically the design patterns helped us a lot in closing the first version of the modular architecture. What stands out apart from the his extensive knowledge is the commitment to his work and the ability to deliver despite the unreasonable deadlines.\nGiven a chance, I would want to work with him again.",
        date: "March 8, 2020",
        image: require("../../../assets/references/chakshu.jpeg"),
        linkedin: "https://www.linkedin.com/in/chakshu-gautam-6918736b/",
    },
    {
        referencee: "Shelly Sachdeva",
        referenceeTitle: "Associate Professor & Head of Department at National Institute of Technology Delhi",
        relation: "Shelly was Pranav’s mentor",
        content: "Pranav is a diligent, intelligent, well-rounded, and highly talented person. He has very good at his coding skills. His determination towards carrying the task is really admirable. He did implementation related to different databses during his undergraduate study.",
        date: "September 16, 2019",
        image: require("../../../assets/references/shelly.jpeg"),
        linkedin: "https://www.linkedin.com/in/shelly-sachdeva-5b858318/",
    },
    {
        referencee: "Pushkar Limaye",
        referenceeTitle: "Co-Founder & CTO at Carnot Technologies Pvt. Ltd.",
        relation: "Pushkar managed Pranav directly",
        content: "Pranav joined Carnot as an intern, and eventually went on to lead the Android team.\nRight from his intern days, i've seen his strong work ethic, thus always putting that extra mile towards his work. In a way , Pranav exemplifies a right mix of talent, discipline and perfection. I find him extremely curious about getting in-depth understanding of software components, and successfully applying the same in his work. These are skills which I have seldom seen as a combination, in a single person.\nI wish Pranav great success in his career. I am sure Pranav will be a great value add to any software team, both as a developer and as a leader.",
        date: "September 15, 2019",
        image: require("../../../assets/references/pushkar.jpeg"),
        linkedin: "https://www.linkedin.com/in/pushkar-limaye-23852455/",
    },
    {
        referencee: "Rohan Vadgaonkar",
        referenceeTitle: "Co-Founder & CEO at Carnot Technologies Pvt. Ltd.",
        relation: "Rohan was senior to Pranav but didn’t manage directly",
        content: "Pranav joined Carnot as an intern when we were just a 10 member team. He went on to become our lead android engineer managing a team of 3 in his sub system. Pranav is a great colleague - he is highly self motivated and was able to navigate and structure the chaos of a fast growing startup. He is a team player and has good interpersonal skills. Most importantly, he is keenly interested in software development trying to set his own internal bar and push it. I am confident Pranav will be a great addition to any team he decides to work with in the future",
        date: "July 4, 2019",
        image: require("../../../assets/references/rohan.jpeg"),
        linkedin: "https://www.linkedin.com/in/rohan-vadgaonkar-b03a9b70/",
    },
]
