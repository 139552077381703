import React, { Fragment } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import WorkExperienceView from './WorkExperienceView'
import { workex, education } from './experiencedata'
import AcademicExperienceView from './AcademicExperienceView'

const styles = theme => ({
    container: {
        margin: theme.spacing(4),
        paddingRight: theme.spacing(8),
        paddingLeft: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1),
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1440,
            marginRight: 'auto',
            marginLeft: 'auto',
        },
    },
    headingContainer: {
        minWidth: 275,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: theme.spacing(2),
    },
    content: {
        paddingTop: 20,
        paddingBottom: 20,
        fontFamily: "'Jost', sans-serif",
        fontSize: "1.5em"
    },
    title: {
        fontFamily: "'Ubuntu', sans-serif",
        fontSize: "2.8em",
    },
    subsection: {
        fontFamily: "'Ubuntu', sans-serif",
        paddingTop: 10,
        fontSize: "2em",
    },
    gridContainer: {
        marginBottom: theme.spacing(1),
    },
})

export default withStyles(styles)(({ classes, id }) => {
    return <Fragment>
        <div id={id} className={classes.container}>
            <div className={classes.headingContainer}>
                <Typography className={classes.title} variant="h3" gutterBottom>
                    My Journey
                    </Typography>
                <Typography variant="body1" className={classes.content} color="textSecondary" gutterBottom>
                    During my short journey (so far) as a software engineer,  I've been fortunate enough to have had the privilege of working at some amazing places and doing interesting & meaningful work. Here's an overview for you <span role="img" aria-label="smiley">😄</span>
                </Typography>
            </div>
            <Typography gutterBottom className={classes.subsection}>
                My work experience <span role="img" aria-label="direction">👉🏼</span>
            </Typography>
            <Grid container spacing={2} className={classes.gridContainer} direction="column">
                {workex.map((company, index) =>
                    <Grid item xs={12} key={company.companyName}>
                        <WorkExperienceView
                            companyName={company.companyName}
                            companyLogo={company.companyLogo}
                            work={company.work}
                            index={index}
                            companyLink={company.companyLink} />
                    </Grid>
                )}
            </Grid>
            <Typography gutterBottom className={classes.subsection}>
                My academic journey <span role="img" aria-label="direction">👉🏼</span>
            </Typography>
            <Grid container spacing={2} direction="row">
                {education.map((experience, index) =>
                    <Grid item xs={12} key={experience.instituteName} style={{ height: "100%" }}>
                        <AcademicExperienceView
                            index={index}
                            education={experience} />
                    </Grid>
                )}
            </Grid>
        </div>
    </Fragment>
});