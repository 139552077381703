import React, { Component, Fragment } from 'react'
import { withStyles, Grow, List, ListItem, Typography, Grid, ListItemText, Divider, Paper } from '@material-ui/core'
import { technologies } from './skillstore'
import VizSensor from 'react-visibility-sensor'

const styles = theme => ({
    frameworkTextPrimary: {
        fontFamily: "'Jost', sans-serif",
    },
    gridContainer: {
        marginBottom: theme.spacing(2),
    },
    verticalDivider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    gridSurface: {
        width: "100%",
    },
});

export default withStyles(styles)(class extends Component {

    constructor(props) {
        super(props);
        this.state = {
            componentVisible: false
        };
        this.componentRendered = false;
    }

    componentDidMount = () => {
        this.componentRendered = true;
    }

    render() {
        const { classes, width } = this.props;
        return <VizSensor onChange={(isVisible) => {
            if (!this.state.componentVisible && this.componentRendered) {
                this.setState({
                    componentVisible: isVisible
                });
            }
        }} partialVisibility>
            <Grow in={this.state.componentVisible}
                style={{ transformOrigin: '0 0 0', transitionDelay: '200ms' }}
                {...(this.state.componentVisible ? { timeout: 1000 } : {})}>
                <Paper elevation={3} className={classes.gridSurface}>
                    <Grid container
                        justify="space-evenly">
                        <Grid item xs={12} sm={5} className={classes.listSecondSection}>
                            <List
                                className={classes.gridContainer}>
                                {technologies.map((technology, index) =>
                                    index < (width !== 'xs' ? technologies.length / 2 : technologies.length) ?
                                        <Fragment key={index}>
                                            <ListItem>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Typography className={classes.frameworkTextPrimary} variant="h6">
                                                                {technology.image}&nbsp;&nbsp;{technology.name}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                    secondary={"Proficiency: " + technology.proficiency}
                                                />
                                            </ListItem>
                                            {width === 'xs' && index !== (technologies.length - 1) ? <Divider />
                                                : width !== 'xs' && index !== (technologies.length / 2) - 1 ? <Divider /> : null}
                                        </Fragment> : null,
                                )}
                            </List>
                        </Grid>
                        {
                            width !== 'xs' ?
                                <Fragment>
                                    <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
                                    <Grid item sm={5}>
                                        <List
                                            className={classes.gridContainer}>
                                            {technologies.map((technology, index) =>
                                                index >= technologies.length / 2 ?
                                                    <Fragment key={index}>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={
                                                                    <React.Fragment>
                                                                        <Typography className={classes.frameworkTextPrimary} variant="h6">
                                                                            {technology.image}&nbsp;&nbsp;{technology.name}
                                                                        </Typography>
                                                                    </React.Fragment>
                                                                }
                                                                secondary={"Proficiency: " + technology.proficiency}
                                                            />
                                                        </ListItem>
                                                        {index !== technologies.length - 1 ? <Divider /> : null}
                                                    </Fragment>
                                                    : null,
                                            )}
                                        </List>
                                    </Grid>
                                </Fragment> : null
                        }
                    </Grid>
                </Paper>
            </Grow>
        </VizSensor>
    }
});