import React, { Component } from 'react'
import { Grid, Typography, Button, withWidth, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import WelcomeBg from '../../assets/background.webp'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import firebase from '../../Firebase'


const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        height: "100vh",
        width: "100%",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundImage: `url(${WelcomeBg})`,
        [theme.breakpoints.down('xs')]: {
            overflowX: "auto",
        }
    },
    container: {
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100% - 64px)',
            paddingTop: 64
        },
        [theme.breakpoints.down('xs')]: {
            height: 'calc(100% - 56px)',
            paddingTop: 56
        },
        height: "100%",
        width: "100%",
    },
    paper: {
        padding: 20,
        width: "80%",
        margin: "0 auto",
        textAlign: "center",
        fontWeight: 700
    },
    avatarContainer: {
        display: "flex",
        [theme.breakpoints.up('md')]: {
            height: "50%",
        },
        [theme.breakpoints.only('sm')]: {
            height: "40%",
        },
        [theme.breakpoints.only('xs')]: {
            height: "30%",
        },
    },
    contentContainer: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up('md')]: {
            height: "50%",
        },
        [theme.breakpoints.only('sm')]: {
            height: "60%",
        },
        [theme.breakpoints.only('xs')]: {
            height: "70%",
        },
    },
    avatar: {
        maxWidth: 400,
        maxHeight: 400,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 300,
            maxHeight: 300,
        },
        height: "100%",
        width: "auto",
        borderRadius: "50%",
        margin: "0 auto",
        alignSelf: "flex-end",
    },
    item: {
        width: "100%",
        height: "100%",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    name: {
        fontFamily: "'Ubuntu', sans-serif",
        width: "90%",
        margin: "0 auto",
        textAlign: "center",
        fontSize: "2rem",
        [theme.breakpoints.up('md')]: {
            fontSize: "3.4rem",
        },
        color: grey[300],
    },
    headline: {
        width: "80%",
        margin: "0 auto",
        textAlign: "center",
        fontSize: "0.8rem",
        [theme.breakpoints.up('md')]: {
            fontSize: "1.2rem",
        },
        color: grey[300],
    },
    button: {
        margin: theme.spacing(1),
    },
})

export default withStyles(styles)(withWidth()(class extends Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            open: false,
        }
    }

    downloadResume = () => {
        var storage = firebase.storage();
        var analytics = firebase.analytics();
        const storageRef = storage.ref('/');
        var self = this;
        storageRef.child('Pranav_Sharma_resume.pdf').getDownloadURL().then(function (url) {
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = function (event) {
                var blob = xhr.response;
                self.saveBlob(blob, "Pranav's Resume.pdf");
            };
            xhr.open('GET', url);
            xhr.send();
        }).catch(function (error) {
            console.log(error);
            analytics.logEvent('exception', {
                content_type: 'error',
                content: error
            })
        });
        this.setState({
            open: true,
        });
        analytics.logEvent('resume_downloaded', {
            content_type: 'event',
            content: 'clicked download resume'
        });
    }

    saveBlob = (blob, fileName) => {
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        a.dispatchEvent(new MouseEvent('click'));
    }

    handleClose = () => {
        this.setState({
            open: false
        });
    }

    render() {
        const { classes } = this.props;
        const { open } = this.state;
        return <div className={classes.root}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                className={classes.container}>
                <Grid item className={classes.item} xs={12}>
                    <div className={classes.avatarContainer}>
                        <img src={require('../../assets/profile.jpeg')} alt="Pranav Sharma" className={classes.avatar} />
                    </div>
                    <br />
                    <div className={classes.contentContainer}>
                        <Typography variant="body1" className={classes.name} color="inherit">
                            Pranav Sharma
                        </Typography>
                        <br />
                        <Typography variant="overline" display="block" className={classes.headline}
                            fontWeight="fontWeightBold">
                            Software Engineer | MS Computer Science
                        </Typography>
                        <br />
                        <Typography align="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.downloadResume()}
                                className={classes.button}
                                startIcon={<CloudDownloadIcon />}>
                                Download Resume
                            </Button>
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={() => this.handleClose()}>
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={() => this.handleClose()} severity="success"
                    color="info">
                    Thank you for giving me space on your device ! <span role="img" aria-label="smiley">😄</span>
                </Alert>
            </Snackbar>
        </div>
    }
}));